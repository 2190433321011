<script setup lang="ts">
import { LargeModalLayout } from '@/ui/components'
import { Button, Column, Row, Tabs, Textarea } from '@madxnl/dodo-ui'
import { computed, ref, watchEffect } from 'vue'
import { BlockSettings, type SettingsField } from '../sidebar'
import { useBlockSettingsContext } from '../sidebar/useBlockSettingsContext'
import PromptFieldEditor from './PromptFieldEditor.vue'

const props = defineProps<{
  field: SettingsField
  disabled: boolean
}>()

const emit = defineEmits<{
  changevalue: [value: string | null]
}>()

const modelValue = ref('')
const modalOpen = ref(false)
const name = computed(() => props.field.namePath[props.field.namePath.length - 1]!)
const { blockConfig } = useBlockSettingsContext()

watchEffect(() => {
  modelValue.value = String(props.field.data ?? '')
})

watchEffect(() => {
  const value = modelValue.value || null
  const changed = value != props.field.data
  if (changed) emit('changevalue', value)
})

function clickField() {
  modalOpen.value = true
}
</script>

<template>
  <Column>
    <PromptFieldEditor
      :model-value="modelValue"
      :field="field"
      :readonly="true"
      style="max-height: 200px"
      @click="clickField"
    />
  </Column>

  <LargeModalLayout
    size-xl
    :open="modalOpen"
    :title="`${disabled ? 'View' : 'Edit'} ${name}`"
    @close="modalOpen = false"
  >
    <template #content>
      <Row gap="l" align="start">
        <Column grow>
          <Tabs
            :tabs="[
              { name: 'Editor', slot: 'editor' },
              { name: 'Text', slot: 'text' },
            ]"
          >
            <template #editor>
              <Row gap="l">
                <PromptFieldEditor v-model="modelValue" :field="field" :disabled="disabled" />
              </Row>
            </template>
            <template #text>
              <Textarea v-model="modelValue" :disabled="disabled" :max-rows="20" />
            </template>
          </Tabs>
        </Column>
        <Column style="width: 254px">
          <BlockSettings :block-config="blockConfig" :hide-fields="['prompt', 'model']" context="settings" />
        </Column>
      </Row>
    </template>
    <template #footer="{ close }">
      <Row>
        <Button variant="solid" color="primary" @click="close">Done</Button>
      </Row>
    </template>
  </LargeModalLayout>
</template>
